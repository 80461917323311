
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Loading from "vue-loading-overlay";

interface editBank {
  name: string;
  picBank: string;
  note: string;
}

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Loading,
    Form,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    const editBankValidator = Yup.object().shape({
      name: Yup.string().required("Nama Bank Wajib Diisi"),
      picBank: Yup.string().required("Penanggung Jawab Wajib Diisi"),
    });

    const editBank = reactive<editBank>({
      name: "",
      picBank: "",
      note: "",
    });

    const getIdBank = () => {
      isLoading.value = true;
      ApiService.getWithoutSlug(
        "crmv2/main_bank/bank/office/" + route.params.id
      )
        .then((res) => {
          editBank.name = res.data.name;
          editBank.picBank = res.data.picBank;
          editBank.note = res.data.note;
          isLoading.value = false;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
          isLoading.value = false;
        });
    };

    const saveChanges1 = () => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      isLoading.value = true;

      // dummy delay
      setTimeout(() => {
        // Send login request

        ApiService.putWithData(
          "crmv2/main_bank/bank/office/" + route.params.id,
          editBank
        )
          .then((res) => {
            toast.success("Berhasil Update Bank Pusat");
            isLoading.value = false;

            router.push({ path: "/superadmin/bank-center" });
          })
          .catch((e) => {
            toast.error(e.response.data.detail);
            isLoading.value = false;
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_BANK);
      setCurrentPageBreadcrumbs("Bank Pusat", ["Data"]);
      getIdBank();
      getIdBank();
    });

    return {
      submitButton,
      saveChanges1,
      editBank,
      editBankValidator,
      getIdBank,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
